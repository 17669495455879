.analytics {
  width: 85%;
  height: max-content;
}

.top {
  width: 100%;
  height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top1 {
  width: 50%;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.top2 {
  width: 50%;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.top2 h1 {
  width: 90%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
}

.top2 p {
  width: 80%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.3rem;
}

.top2 .btnTop {
  width: 100%;
  height: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .4s;
}

.top2 .btnTop button:hover {
    background-color: violet;
    color: black;
    font-weight: 550;
}

.top2 .btnTop button {
  width: 55%;
  height: 40%;
  background-color: #8c8cda;
  transition: 0.4s ease-in-out;
  color: white;
  font-size: 2rem;
}


#hrAnal {
    width: 40%;
    height: 5px;
    background-color: white;
    border-radius: 50rem;
    margin: 5rem auto;
    box-shadow: 0px 0px 50px;

}

.topBlur {
  filter: blur(100px);
  background-color: rgba(125, 230, 125, 0.623);
  z-index: -1;
  position: absolute;
  margin-top: 1.5rem;
  height: 280px;
  width: 400px;
}

.bot {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: max-content;
}

.bot1 {
    width: 50%;
    height: inherit;
    display: flex;
    flex-direction: column;
    gap: 1.8rem;
    
}

.bot1Head {
    width: 100%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#bot1Head h1 {
    font-size: 2.5rem;
}

#bot1Para {
    display: flex;
    height: 60%;
    line-height: 120%;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    font-size: .8rem;
    text-align: left;
}

#bot1Btn {
    width: 100%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#bot1Btn button {
    width: 60%;
    height: 50%;
    background-color: transparent;
    border: 2px solid white;
    color: white;
    font-weight: 600;
    font-size: 1.4rem;
    transition: .4s;
}

#bot1Btn button:hover {
    background-color: rgba(0, 0, 255, 0.377);
}

.bot2 {
    width: 50%;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.bot2Blur {
    filter: blur(100px);
    width: 400px;
    position: absolute;
    margin-left: -4rem;
}

/* <<<<< Mobile >>>>> */

@media screen and (max-width:768px) {
    .analytics {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: max-content;
        align-items: center;
    }
    .top {
        height: max-content;
        flex-direction: column;
        /* display: none; */
        width: 100%;
        /* border: 1px solid red; */
    }

    .top1 {
        width: 100%;
        height: max-content;
        flex-direction: column;
    }

    .top1 img {
        width: 80%;
    }

    .top2 {
        flex-direction: column;
        width: 100%;
    }

    .top2 h1 {
        text-align: center;
        font-size: 1.8rem;
    }

    .topBlur {
        width: 300px;
        height: 180px;
        filter: blur(100px);
    }

    #hrAnal {
        margin: 2rem auto;
        width: 50%;
        height: 2px;
    }

    .bot {
        flex-direction: column;
    }

    .bot1 {
        width: 95%;
        height: max-content;
        display: flex;
        flex-direction: column;
        /* margin-top: 25rem; */
        gap: 1rem;
    }
    .bot1Head {
        height: max-content;
        font-size: .8rem;
        text-align: center;
    }

    #bot1Para {
        width: 95%;
        margin: 0 auto;
    }

    #bot1Btn {
        margin-top: 2rem;
    }

    #bot1Btn button {
        width: 80%;
    }

    .bot2 {
        width: 100%;
        height: max-content;
        margin-top: 2rem;
    }

    .bot2Blur {
        background-color: green;
        filter: blur(70px);
        margin-left: -2rem;
        margin-top: 2rem;
        width: 300px;
        height: 200px;
        z-index: -1;

    }
       
    .bot2 img {
        width: 80%;
    }
}