.buyNFTs {
    width: 85%;
    height: 900px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow: scroll;
    margin-bottom: 2rem;
}

.buyNFTs > h1 {
    font-family: fantasy;
    font-size: 60px;
}

.buyList {
    width: 70vw;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    place-items: center;
}

.buyItem {
    border-radius: 15px;
    width: 300px;
    height: 350px;
    margin: 20px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
    overflow: hidden;
}

.buyItem:hover {
    box-shadow: 0px 3px 15px rgba(219, 207, 207, 0.664);
    transition: 0.3s ease-in;
    cursor: pointer;
}

.buyItem div {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    width: 100%;
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.buyItem h1, .buyItem p {
    margin-left: 20px;
    text-align: center;
}

@media only screen and (max-width: 1300px) {
    .buyList {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  @media only screen and (max-width: 800px) {
    .buyList {
      grid-template-columns: 1fr;
    }
  }