.ready {
    width: 85%;
    height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to bottom, #2e587dce, #181025);
    border-radius: 1rem;
    gap: 3rem;
  }

  .ready > :nth-child(1) {
    color: #babbf0;
    font-size: 1.6rem;
  }
  
  .ready > :nth-child(2) {
    color: white;
    font-size: 2rem;
  }
  
  .ready > :nth-child(3) > button {
    width: 28rem;
    height: 40px;
    background-color: #8c8cda;
    color: white;
    font-weight: 600;
    font-size: 1.7rem;
    transition: .4s;
  }

  .ready > :nth-child(3) > button:hover {
    background-color: whitesmoke;
    color: black;
    border: none;
  }

  @media screen and (max-width:768px) {
    .ready {
        width: 95%;
    }

    .ready > :nth-child(2) {
        color: white;
        font-size: 1.6rem;
        text-align: center;
      }

      .ready > :nth-child(3) > button {
        width: 15rem;
      }
  }