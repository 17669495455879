.testimonials {
  width: 85%;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tTop {
    width: 100%;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tTop > h3 {
    font-size: 2rem;
}

.tBot {
    width: 100%;
    display: flex;
    height: 650px;
    align-items: center;
    justify-content: flex-start;
    overflow-x: scroll;
    gap: 1rem;
}

.comments {
    width: 400px;
    min-width: 400px;
    max-width: 400px;
    height: 450px;
    min-height: 450px;
    max-height: 450px;
    border: 2px solid white;
    border-radius: 2rem;
    margin-right: 1rem;
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 0;
    overflow: visible;
}

.comments .comImg {
    width: 100%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.comments .comImg img {
    border-radius: 50%;
    width: 40%;
    z-index: 1;
    margin-top: -6rem;
}

.comments .comHd {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
    font-weight: 600;
    color: rgba(172, 255, 47, 0.781);
}

.commHR {
    width: 88%;
}

.comments .comPara {
    width: 90%;
    height: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    font-size: 140%;
    line-height: 200%;
    font-style: italic;
    color: rgba(255, 255, 255, 0.788);
}




@media screen and (max-width:768px) {
    .testimonials {
        width: 100%;
        margin-top: 2rem;
    }

    .tTop > h3 {
        font-size: 1.4rem;
    }

    .tBot {
        gap: 0rem;
        margin-right: .5rem;
    }

    .comments {
        min-width: 300px;
        max-width: 300px;

    }
}