.App {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  font-family: 'Times New Roman', Times, serif;
  width: 100%;
  height: min-content;
  background-color: #010233;
  color: rgba(245, 245, 245, 0.959);
}

::-webkit-scrollbar {
  display: none;
}

button {
  border-radius: 5rem;
  border: none;
  outline: none;
}

button:hover {
  cursor: pointer;
}

.blur {
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.301);
  position: absolute;
}

/*  */