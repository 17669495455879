.login {
  width: 85%;
  height: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.log2 {
  width: 510px;
  height: 680px;
  border-radius: 1rem;
  /* margin-left: -.6rem; */
  margin-top: -3rem;
  background-color: #babbf031;
  filter: blur(20px);
}

.mainWrapper {
  width: 450px;
  height: 650px;
  margin-top: -3rem;
  background-color: #babbf048;
  border-radius: 1rem;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loginPage {
  width: 95%;
  height: 400px;
  display: flex; /* make flex */
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.signUpPage {
  width: 95%;
  height: 650px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.heading {
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
}

.form {
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: black; */
}

.form form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.forms {
  display: flex;
}

.forms > :nth-child(1) {
  width: 40%;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.forms > input {
  width: 60%;
  height: 50px;
  border: none;
  border-radius: 0;
  background-color: transparent;
  border-bottom: 2px solid white;
}

.btn {
  width: 100%;
  height: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn button {
  width: 60%;
  height: 30px;
  background-color: #8c8cda;
  color: white;
  font-weight: 600;
  font-size: 1.2rem;
  transition: 0.4s;
}

.btn button:hover {
  background-color: whitesmoke;
  color: black;
  border: none;
}

.noAcc {
  display: flex;
  width: 100%;
  height: 15%;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.noAcc p {
  font-size: 1.2rem;
}

.noAcc button {
  width: 100px;
  height: 30px;
  background-color: #8c8cda;
  color: white;
  font-weight: 600;
  font-size: 1rem;
  transition: 0.4s;
}

.noAcc button:hover {
  background-color: whitesmoke;
  color: black;
  border: none;
}

@media screen and (max-width: 768px) {
  .login {
    width: 100%;
    height: 640px;
  }

  .log2 {
    width: 99%;
    height: 700px;
    /* margin-top: 2rem; */
    /* margin-bottom: 6rem; */
  }

  .mainWrapper {
    width: 95%;
    height: 600px;
    margin-top: .1rem;
    /* margin-bottom: 6rem; */
  }

  .divl {
    font-size: 1rem;
    word-wrap: break-word;
  }

  button {
    font-size: 1rem;
    width: 100px;
    height: 50px;
  }

  .noAcc {
    gap: .7rem;
  }

  
}
