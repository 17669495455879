.feature {
    width: 85%;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

h3 {
    padding: 0 1rem;
}

.ftBox {
    width: 90%;
    height: 80%;
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0.103);
    border-radius: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

/* <<<< Mobile >>>> */

@media screen and (max-width:768px) {
    .feature {
        width: 100%;
        height: max-content;
        margin-top: 0rem;
    }

    .ftBox {
        flex-direction: column;
        width: 80%;
        height: 300px;
        padding: 0 0 2rem 0rem;
        gap: 2.5rem;
    }
}