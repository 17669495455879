@media screen  and (min-width:769px) {
    .mobileFooter {
        display: none;
    }
}

@media screen  and (max-width:768px) {
    .mobileFooter{
        width: 100%;
        height: max-content;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
    }

    .mobileFooter > input {
        width: 80%;
    }

    .mobileFooter > ::placeholder {
        font-size: 15px;
    }

    .mobileFooter > button {
        width: 50%;
        margin: 1rem 0rem;
        background-color: #8c8cda;
        font-weight: 600;
        font-size: 1.7rem;
    }
}