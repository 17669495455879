.footer {
  width: 85%;
  height: max-content;
}

table {
  width: 100%;
  height: max-content;
}

th {
  font-size: 1.5rem;
}

td {
  font-size: 1.2rem;
}

th,
td {
  text-align: center;
  padding: 20px 5px;
}

td button {
  width: 10rem;
  height: 30px;
  border-radius: 2rem;
  font-weight: 700;
  background-color: #8c8cda;
  color: white;
  font-size: 18px;
}

input {
    width: 40%;
    height: 27px;
    padding: 0 15px;
    outline: none;
    border-radius: 2rem;
    border: 2px solid white;
    background-color: rgba(127, 255, 212, 0.616);
    color: white;
    font-size: 18px;
  }
  
  ::placeholder {
    color: white;
    font-size: 15px;
    text-shadow: 1px 1px 1px black;
  }

/* <<<< Mobile >>>> */

@media screen and (max-width: 768px) {
  .footer {
    display: none;
  }
}
