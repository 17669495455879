.navbar {
    width: 85%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 100;
}

nav {
    width: 100%;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    font-size: 2rem;
}

.homepage a {
    font-weight: 700;
    color: white;
    text-decoration: none;
}

.menu {
    width: 60px;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: .3rem;
    display: none;
}

.menu > span {
    width: 60%;
    height: 4px;
    border-radius: 50rem;
    background-color: white;
}

nav > ul {
    display: flex;
    gap: 5rem;
}

nav > ul > li {
    list-style: none;
}

nav > ul > li > a {
    text-decoration: none;
    color: rgba(245, 245, 245, 0.959);
    font-size: 1.6rem;
    cursor: pointer;
}





/* <<< Mobile >>> */

@media screen and (max-width:768px) {
    .navbar {
        width: 100%;
    }
    nav {
        font-size: 1.5rem;
    }
    .menu {
        display: flex;
    }
    nav > ul {
        display: none;
        width: 10rem;
        position: absolute;
        flex-direction: column;
        gap: 5rem;
        margin-top: 30rem;
        right: 0;
        background-color: rgba(0, 0, 0, 0.582);
        backdrop-filter: blur(10px);
        border-radius: 2rem 0 0 2rem;
        padding: 2rem 1rem;
        text-align: center;
    }

    nav > ul > li > a {
        font-weight: 600;
    }

    nav > ul.open {
        display: flex;
    }
    
}