.hero {
    display: flex;
    align-items: center;
    width: 85%;
    height: 500px;
}

.heroL {
    width: 60%;
    height: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.heroHeading {
    font-size: 2rem;
    display: flex;
    width: 100%;
    height: 40%;
    align-items: center;
    justify-content: center;
    font-weight: 700;
}

.heroPara {
    font-size: 1.8rem;
    width: 100%;
    height: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.heroPara h4 {
    text-align: center;
    line-height: 150%;
}

.heroButtons {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 35%;
}

.heroButtons button {
    width: 30%;
    height: 25%;
    font-size: 1.5rem;
    font-weight: 600;
}

.hrBtn1 {
    background-color: green;
}

.hrBtn1 > a {
    color: white;
    transition: .4s;
    text-decoration: none;
}

.hrBtn1:hover {
    background-color: aqua;
    color: rgb(3, 58, 32);
}

.hrBtn2 {
    background-color: rgba(255, 0, 0, 0.705);
    color: white;
    transition: .4s;
}

.hrBtn2 > a {
    text-decoration: none;
    color: white;
}

.hrBtn2:hover {
    background-color: red;
    color: black;
}

.heroR {
    width: 40%;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.heroR img {
    width: 90%;
}

.heroBlur {
    height: 430px;
    width: 550px;
    background-color: green;
    margin-top: 1rem;
    filter: blur(120px);
    z-index: -1;
}



/* << Mobile >> */

@media screen and (max-width:768px) {
    .hero {
        width: 100%;
        flex-direction: column;
    }

    .heroL {
        width: 100%;
        height: inherit;
    }

    .heroHeading {
        font-size: 1rem;
        text-align: center;
    }

    .heroPara {
        font-size: 1rem;
    }

    .heroButtons button{
        height: 40%;
        width: 30%;
        font-size: 1rem;
    }

    .heroR {
        width: 100%;
        height: inherit;
    }

    .heroR img {
        width: 60%;
    }

    .heroBlur {
        filter: blur(80px);
        height: 200px;
        width: 200px;
    }
}